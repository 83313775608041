import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Spinner } from "reactstrap";
import MainNavigation from "../components/MainNavigation";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import SignUpForm from "../components/SignUpForm";
import {toast} from 'react-toastify';
import { createTheme, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import smile from "../assets/img/smile.svg";
import angry from "../assets/img/angry.svg";
import happy from "../assets/img/happy.svg";
import { makeStyles, withStyles } from "@mui/styles";
import { addPlateformFeedBack } from "../store/actions/restaurantsAction";
import { useHistory } from "react-router-dom";
import { yellow } from "@mui/material/colors";
const FeedBack = () => {
    const { darkMode, color } = useSelector((state) => state.global);

    const mood = [
        { img: '🙂', name: "happy" },
        { img: '😐', name: "neutral" },
        { img: "😕", name: "unhappy" },
    ];
    const [serviceExperience, setServiceExperience] = useState();
    const { singleUser } = useSelector((state) => state.user);
    const [rating, setRating] = useState();
    const [feedbackData, setFeedBack] = useState({
        name: "",
        email: "",
        comment: "",
        userID: "",
    });
    useEffect(() => {
        setFeedBack({
            name: singleUser?.name,
            email: singleUser?.email,
            userID: singleUser?.id,
        });
    }, [singleUser]);
    const handleChangeData = (e) => {
        setFeedBack((obj) => ({
            ...obj,
            [e.target.name]: e.target.value,
        }));
    };

    const theme = createTheme({
        palette: {
            secondary: {
                main: `${color ? color : "#000000"}`,
            },
        },
    });
    const inputProps = {
        className: darkMode
            ? "my-notched-outline-dark"
            : "my-notched-outline-light",
    };

    const labelProps = {
        style: {
            color: darkMode ? "#95989a" : "rgb(101 92 92)",
        },
    };

    const inputColor = darkMode ? "#ffffff" : "rgb(101 92 92)";

    const CustomTextField = withStyles({
        root: {
            "& fieldset": {
                borderColor: darkMode ? "#95989a" : "rgba(0, 0, 0, 0.125)",
            },
        },
    })(TextField);
    const [selectedMood, setSelectedMood] = useState(null); // Track selected mood

    const handleMoodClick = (name) => {
        setSelectedMood(name);
        setServiceExperience(
            name
        );
    };
    const ratingChanged = (newRating) => {
        setRating(newRating);
    };
    const { requestLoading } = useSelector((state) => state.restaurants);
    const { uid } = useSelector((state) => state.authUser);

    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <>
            <Container>
                {uid ? (
                    <>
                        <MainNavigation title={"FeedBack"} />
                        <Row className="py-5">
                            <Col>
                                {/* <Row>
                                    <Col>
                                        <div className="text-center">
                                            <h1
                                                className={
                                                    darkMode
                                                        ? "fs--18 fw--600 mb-0  normal-heading-dark"
                                                        : "fs--18 fw--600 mb-0  normal-heading"
                                                }
                                            >
                                                Please Give us 60 Seconds
                                            </h1>
                                        </div>
                                    </Col>
                                </Row> */}

                                <Row className="mt-4">
                                    <Col>
                                        <div className="text-center">
                                            <h4
                                                className={
                                                    darkMode
                                                        ? "fs--18 fw--400 mb-0  normal-heading-dark"
                                                        : "fs--18 fw--400 mb-0  normal-heading"
                                                }
                                            >
                                                Service Efficiency
                                            </h4>
                                        </div>
                                        <Row className="justify-content-center my-3">
                                            {mood.map((item, index) => {
                                                return (
                                                    <h1
                                                    className={`cursor-pointer mx-2 option ${selectedMood === item.name ? 'selected' : ''}`}
                                                        key={index}
                                                        onClick={() => handleMoodClick(item.name)}
                                                    >{item.img}</h1>
                                                );
                                            })}
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="mt-4">
                                    <Col>
                                        <div className="text-center">
                                            <h4
                                                className={
                                                    darkMode
                                                        ? "fs--18 fw--400 mb-0  normal-heading-dark"
                                                        : "fs--18 fw--400 mb-0  normal-heading"
                                                }
                                            >
                                                Overall Experience
                                            </h4>
                                        </div>

                                        <Row className="justify-content-center my-3">
                                            <ReactStars
                                            required
                                                count={5}
                                                name="rating"
                                                onChange={ratingChanged}
                                                size={30}
                                                isHalf={true}
                                                emptyIcon={
                                                    <i className="far fa-star"></i>
                                                }
                                                halfIcon={
                                                    <i className="fa fa-star-half-alt"></i>
                                                }
                                                fullIcon={
                                                    <i className="fa fa-star"></i>
                                                }
                                                activeColor={color}
                                            />
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="d-flex mt-5 justify-content-center align-items-center">
                                        <Card
                                            className={
                                                darkMode
                                                    ? "auth__card-dark"
                                                    : "auth__card"
                                            }
                                        >
                                            <ThemeProvider theme={theme}>
                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        if(!feedbackData.comment || !serviceExperience || !rating){
                                                            toast.error("Please give all the required data");
                                                            return
                                                        }
                                                        let obj = {
                                                            ...feedbackData,
                                                            rating: rating || 0,
                                                            serviceExperience:
                                                                serviceExperience ||
                                                                "",
                                                        };
                                                        if (obj.userID) {
                                                            dispatch(
                                                                addPlateformFeedBack(
                                                                    obj
                                                                )
                                                            );
                                                        } else {
                                                            toast.error(
                                                                "Please login to give feedback"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <CustomTextField
                                                        required
                                                        id="outlined-required"
                                                        label="Name"
                                                        // floatingLabelText="Name"
                                                        fullWidth
                                                        className="mb-3"
                                                        type="text"
                                                        color="secondary"
                                                        name="name"
                                                        defaultValue={
                                                            feedbackData.name
                                                        }
                                                        onBlur={(e) => {
                                                            handleChangeData(e);
                                                        }}
                                                        inputProps={inputProps}
                                                        InputLabelProps={
                                                            labelProps
                                                        }
                                                        InputProps={{
                                                            style: {
                                                                color: inputColor,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    />
                                                    <CustomTextField
                                                        required
                                                        id="outlined-required"
                                                        label="Email"
                                                        // floatingLabelText="Email"
                                                        fullWidth
                                                        className="mb-3"
                                                        type="email"
                                                        color="secondary"
                                                        name="email"
                                                        defaultValue={
                                                            feedbackData.email
                                                        }
                                                        onBlur={(e) => {
                                                            handleChangeData(e);
                                                        }}
                                                        inputProps={inputProps}
                                                        InputLabelProps={
                                                            labelProps
                                                        }
                                                        InputProps={{
                                                            style: {
                                                                color: inputColor,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    />
                                                    <CustomTextField
                                                        // required
                                                        // id="outlined-required"
                                                        label="Your Comments"
                                                        // floatingLabelText="Your Comments"
                                                        fullWidth
                                                        className="mb-3"
                                                        type="text-area"
                                                        color="secondary"
                                                        name="comment"
                                                        defaultValue={
                                                            feedbackData.comment
                                                        }
                                                        onBlur={(e) => {
                                                            handleChangeData(e);
                                                        }}
                                                        inputProps={inputProps}
                                                        InputLabelProps={
                                                            labelProps
                                                        }
                                                        InputProps={{
                                                            style: {
                                                                color: inputColor,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    />
                                                    <Button
                                                        className="py-3 w-100 auth__btn"
                                                        style={{
                                                            background: `${color}`,
                                                        }}
                                                        type="submit"
                                                        disabled={
                                                            requestLoading
                                                        }
                                                    >
                                                        {requestLoading ? (
                                                            <Spinner size="md" />
                                                        ) : (
                                                            "Send"
                                                        )}
                                                    </Button>
                                                </Form>
                                            </ThemeProvider>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <MainNavigation title={"Login to give feedback"} />
                        <Row className="py-5 justify-content-end mx-5"></Row>
                    </>
                )}
            </Container>
        </>
    );
};

export default FeedBack;
