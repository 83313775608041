import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    Row,
    Spinner,
} from "reactstrap";
import HeroSectionCard from "../components/HeroSectionCard";
import ItemCounter from "../components/ItemCounter";
import ItemsDetail from "../components/ItemsDetail";
import MainNavigation from "../components/MainNavigation";
import RecommendedItems from "../components/RecommendedItems";
import { getProduct } from "../store/actions/GlobalAction";
import { addToCartAction } from "../store/actions/cartAction";
import { visitorIncrement } from "../store/actions/orderAction";
import { getRestaurantsAction } from "../store/actions/restaurantsAction";

const ProductDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const [selectedItemsSize, setSelectedItemsSize] = useState({ notes: "" });
    const history = useHistory();
    const { darkMode, color, singleProduct } = useSelector(
        (state) => state.global
    );
    const { uid } = useSelector((state) => state.authUser);
    const { cartItems } = useSelector((state) => state.cart);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);
    useEffect(() => {
        const restaurantID = localStorage.getItem("restaurantID");
        dispatch(getRestaurantsAction(restaurantID));
    }, [dispatch]);

    const currency = singleProduct?.currency;

    const existData = cartItems.find(
        (state) =>
            state?.name === singleProduct?.name &&
            state?.menuItemID === singleProduct?.id
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            dispatch(visitorIncrement(id));
        }
    }, [dispatch, id]);

    const addToCart = (e) => {
        const now = new Date();
        const currentDay = now.toLocaleString("en-US", { weekday: "long" });
        const currentTime = now.getHours() * 100 + now.getMinutes();
        if (restaurantsDetail && restaurantsDetail?.storeTiming) {
            const todayTimings = restaurantsDetail?.storeTiming.find(
                (timing) => {
                    if (timing.day === currentDay) {
                        const startTime = timing.startTime
                            .split(":")
                            .map(Number);
                        const endTime = timing.endTime.split(":").map(Number);
                        const storeStartTime =
                            startTime[0] * 100 + startTime[1];
                        const storeEndTime = endTime[0] * 100 + endTime[1];

                        return (
                            currentTime >= storeStartTime &&
                            currentTime <= storeEndTime
                        );
                    }
                    return false;
                }
            );

            if (
                !todayTimings ||
                !todayTimings.startTime ||
                !todayTimings.endTime
            ) {
                toast.warning("Sorry! the store is closed");
                return false;
            }
        } else {
            toast.error("Unable to retrieve store timings");
            return false;
        }

        e.preventDefault();
        let obj = {
            ...selectedItemsSize,
            currency: currency,
            menuItemID: id,
            name: singleProduct?.name,
            images: singleProduct?.images?.[0] || "",
        };
        if (obj.size === null) {
            toast.warning("select  the size");
        } else {
            dispatch(
                addToCartAction(obj, () => {
                    history.push("/cart");
                })
            );
        }
    };

    useEffect(() => {
        setLoader(true);
        dispatch(
            getProduct(id, () => {
                setLoader(false);
            })
        );
    }, [dispatch, id]);
    useEffect(() => {
        if (singleProduct) {
            localStorage.setItem("restaurantID", singleProduct?.restaurantID);
        }
    }, [singleProduct]);

    return (
        <>
            {loader ? (
                <Container className="product_details d-flex justify-content-center align-items-center">
                    <Spinner color="primary" />
                </Container>
            ) : (
                <Container className="product_details">
                    <MainNavigation title={singleProduct?.name} />
                    <Row>
                        <Col lg={5} md={5}>
                            <HeroSectionCard
                                slideImages={singleProduct?.images}
                                menuID={singleProduct?.id}
                                customerID={uid}
                                title={singleProduct?.name}
                                subTitle={`$ ${singleProduct?.sizes?.[0].price}`}
                                content={singleProduct?.comments}
                                label={singleProduct?.label}
                            />
                        </Col>
                        <Col lg={7} md={7} className="px-1 mt-5">
                            <ItemsDetail
                            id={id}
                            setLoader={setLoader}
                                data={singleProduct?.sizes}
                                currency={currency}
                                cart={singleProduct}
                                existCartData={existData}
                                title={{ title: "Size", subTitle: "" }}
                                setSelectedItemsSize={setSelectedItemsSize}
                            />
 <h4
                    className={
                        darkMode
                            ? "fs--18 fw--600 mb-0  normal-heading-dark"
                            : "fs--18 fw--600 mb-0  normal-heading"
                    }
                >
                    Toppings &nbsp;
                    <span className="fs--16 fw--400 text-muted">
                        {/* {title.subTitle ? title.subTitle : ""} */}
                        (Maximum 2)
                    </span>
                </h4>
                            {/* <ItemsDetail
                            
                                title={{
                                    title: "Toppings",
                                    subTitle: "(Maximum 2)",
                                }}
                            /> */}
                            <Row className="mx-2 py-3 d-flex flex-column justify-content-center align-items-center">
                                <FormGroup
                                    className={
                                        darkMode
                                            ? "top_cards-dark__textarea"
                                            : "top_cards__textarea"
                                    }
                                >
                                    <Input
                                        id="exampleText"
                                        name="text"
                                        value={selectedItemsSize?.notes}
                                        onChange={(e) => {
                                            setSelectedItemsSize((obj) => ({
                                                ...obj,
                                                notes: e.target.value,
                                            }));
                                        }}
                                        type="textarea"
                                        width={100}
                                        rows={4}
                                        placeholder="Your note to Restaurant"
                                    />
                                </FormGroup>
                                <ItemCounter
                                    selectedItemsSize={selectedItemsSize}
                                    setSelectedItemsSize={setSelectedItemsSize}
                                />
                                <Button
                                    style={{ background: `${color}` }}
                                    className="top_cards__addtocart fs--16 fw--400 my-4"
                                    onClick={addToCart}
                                    disabled={
                                        selectedItemsSize.size === null ||
                                        selectedItemsSize.totalPrice === 0
                                    }
                                >
                                    Add to cart{" "}
                                    {selectedItemsSize?.size === null
                                        ? 0
                                        : selectedItemsSize?.totalPrice?.toFixed(
                                              2
                                          )}
                                    &nbsp;
                                    {currency}
                                </Button>
                            </Row>
                            <RecommendedItems
                                data={singleProduct?.recommendedProducts}
                            />
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default ProductDetails;
