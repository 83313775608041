import firebase from "../../config/firebase";
import { toast } from "react-toastify";export const getParentMenus = (id) => async (dispatch) => {
    dispatch(menuLoader(true));
    try {
        await firebase
            .firestore()
            .collection("parentMenus")
            .where("restaurantID", "==", id)
            .where("isDeleted", "==", false)
            .where("isVisible", "==", true)
            .orderBy("position", "asc")
            .onSnapshot(async (querySnapshot) => {
                let parentMenus = [];

                // Iterate through each parent menu document
                for (let doc of querySnapshot.docs) {
                    const parentMenuData = doc.data();
                    
                    // Fetch categories for the current parent menu
                    const categoriesQuerySnapshot = await firebase
                        .firestore()
                        .collection("categories")
                        .where("menuID", "==", doc.id)
                        .where("isDeleted", "==", false)
                        .where("isVisible", "==", true)
                        .get();
                    
                    // Extract category names from the query result
                    const categories = categoriesQuerySnapshot.docs.map(category => category.data().categoryName);

                    // Push parent menu data along with categories
                    parentMenus.push({
                        id: doc.id,
                        ...parentMenuData,
                        categories: categories,
                    });
                }

                // Dispatch the action with parent menus data
                dispatch({
                    type: "PARENT_MENUS",
                    payload: parentMenus,
                });
                console.log(parentMenus,"parentMenus>>>>>>>");
                dispatch(menuLoader(false));
            });
    } catch (error) {
        dispatch(menuLoader(false));
        console.log(error);
    }
};


// export const getParentMenus = (id) => async (dispatch) => {
//     dispatch(menuLoader(true));
//     try {
//         await firebase
//             .firestore()
//             .collection("parentMenus")
//             .where("restaurantID", "==", id)
//             .where("isDeleted", "==", false)
//             .where("isVisible", "==", true)
//             .orderBy("position", "asc")
//             .onSnapshot((query) => {
//                 let parentMenu = [];
//                 for (let doc of query.docs) {
//                     firebase
//                         .firestore()
//                         .collection("categories")
//                         .where("menuID", "==", doc.id)
//                         .where("isDeleted", "==", false)
//                         .where("isVisible", "==", true)
//                         .get()
//                         .then((categoriesQuery) => {
//                             let categories = [];
//                             for (let category of categoriesQuery.docs) {
//                                 categories.push(category.data().categoryName);
//                             }
//                         });
//                     parentMenu.push({
//                         id: doc.id,
//                         ...doc.data(),
//                         categories: categories,
//                     });
//                 }
//                 dispatch({
//                     type: "PARENT_MENUS",
//                     payload: parentMenu,
//                 });
//                 dispatch(menuLoader(false));
//             });
//     } catch (error) {
//         dispatch(menuLoader(false));
//         console.log(error);
//     }
// };
// export const getSearchedParentMenus = (id, searchTerm) => async (dispatch) => {
//     console.log(id, "id>>>>>>>>>>>>>");
//     dispatch(menuLoader(true));
//     try {
//         const menuQuerySnapshot = await firebase
//             .firestore()
//             .collection("categories")
//             .where("restaurantID", "==", id)
//             .where("categoryName", "==", searchTerm)
//             .where("isDeleted", "==", false)
//             .where("isVisible", "==", true)
//             .get();
//         console.log(menuQuerySnapshot, "menuquerysnapshot>>>>>>>>>>");
//         let parentMenuIds = [];

//         // Collect parent menu IDs from matching menus
//         menuQuerySnapshot.forEach((doc) => {
//             const menuData = doc.data();
//             parentMenuIds.push(menuData.restaurantID);
//         });
//         console.log(parentMenuIds, "parentMenuIds>>>>>>>>");
//         await firebase
//             .firestore()
//             .collection("parentMenus")
//             .where("restaurantID", "in", parentMenuIds)
//             .where("isDeleted", "==", false)
//             .where("isVisible", "==", true)
//             .orderBy("position", "asc")
//             .onSnapshot((querySnapshot) => {
//                 let parentMenus = [];
//                 querySnapshot.forEach((doc) => {
//                     parentMenus.push({ id: doc.id, ...doc.data() });
//                 });
//                 console.log(parentMenus, "parentMenus>>>>>>>>>>");

//                 dispatch({
//                     type: "PARENT_MENUS",
//                     payload: parentMenus,
//                 });
//                 dispatch(menuLoader(false));
//             });
//     } catch (error) {
//         dispatch(menuLoader(false));
//         console.log(error);
//     }
// };
export const getMenuCategories = (id) => async (dispatch) => {
    dispatch(categoriesLoader(true));
    dispatch(menuLoader(true));
    await firebase
        .firestore()
        .collection("categories")
        .where("menuID", "==", id)
        .where("isDeleted", "==", false)
        .where("isVisible", "==", true)
        .onSnapshot(async (query) => {
            let menuCategories = [];
            for (let doc of query.docs) {
                if (doc) {
                    menuCategories.push({ id: doc.id, ...doc.data() });
                }
            }
            dispatch(categoriesLoader(false));
            await dispatch({
                type: "MENU_CATEGORIES",
                payload: menuCategories,
            });
        });
};

export const getMenuItems = (id, categoryID) => async (dispatch) => {
    dispatch(menuLoader(true));
    try {
        firebase
            .firestore()
            .collection("menus")
            .where("restaurantID", "==", id)
            .where("categoriesID", "==", categoryID)
            .where("isDeleted", "==", false)
            .where("isVisible", "==", true)
            .onSnapshot(async (query) => {
                let menuItem = [];
                if (!query.empty) {
                    for (let doc of query.docs) {
                        // console.log(doc.data(), "doc data");
                        const label = doc.data().label;
                        // console.log({ label });
                        let labelsData = [];
                        label.forEach((item) =>
                            firebase
                                .firestore()
                                .collection("labels")
                                .doc(item.id)
                                .get()
                                .then((labelDoc) => {
                                    let labData = labelDoc.data();
                                    labelsData.push({
                                        labelName: labData?.labelName,
                                        labelURL: labData?.labelURL,
                                    });
                                })
                        );
                        menuItem.push({
                            id: doc.id,
                            ...doc.data(),
                            label: labelsData,
                        });
                    }
                }
                dispatch(menuLoader(false));
                dispatch({ type: "MENU_ITEMS", payload: menuItem });
            });
    } catch (err) {
        toast.error(err.message);
    }
};

export const menuLoader = (val) => async (dispatch) => {
    dispatch({
        type: "MENU_LOADER",
        payload: val,
    });
};
export const categoriesLoader = (val) => async (dispatch) => {
    dispatch({
        type: "CATEGORIES_LOADER",
        payload: val,
    });
};
