import React from "react";
import { Col, Row } from "reactstrap";
import backIcon from "../assets/img/backIcon.svg";
import backIconDark from "../assets/img/backIconDark.svg";
import bell from "../assets/img/bell.svg";
import bellDark from "../assets/img/bellDark.svg";
import cartDark from "../assets/img/cartDark.png";
import cart from "../assets/img/cart.png";

import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const MainNavigation = ({ title }) => {
    const location = useLocation();
    const history = useHistory();
    const pathname = location.pathname;

    const { darkMode } = useSelector((state) => state.global);

    return (
        <>
            <Row className="pt-3 pb-4 d-flex justify-content-center align-items-center">
                <Col className="col-4">
                    <img
                        alt="backIcon"
                        className="cursor-pointer"
                        src={darkMode ? backIconDark : backIcon}
                        onClick={() => history.goBack()}
                    />
                </Col>
                <Col className="col-4 d-flex justify-content-center">
                    <h4
                        className={
                            darkMode
                                ? " fs--25 fw--600 mb-0  normal-heading-dark"
                                : " fs--25 fw--600 mb-0  normal-heading"
                        }
                    >
                        {title ? title : ""}
                    </h4>
                </Col>
                <Col className="col-4 d-flex justify-content-end">
                    {pathname === "/table" || pathname === "/comparison" ? (
                        <div>
                            <img
                                src={darkMode ? cartDark : cart}
                                alt="cartDark"
                                className="mx-2 table__icons"
                            />
                            <img
                                src={darkMode ? bellDark : bell}
                                alt="bellDark"
                                className="mx-2 table__icons"
                            />
                        </div>
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

export default MainNavigation;
