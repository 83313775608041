import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import Stepper from "../components/Stepper";
import burger from "../assets/img/burger.svg";
import coffee from "../assets/img/coffee.svg";
import cash from "../assets/img/cash 1.svg";
import { useDispatch, useSelector } from "react-redux";
import PricingSection from "../components/PricingSection";
import OrderedItems from "../components/OrderedItems";
import GeneralInfoSection from "../components/GeneralInfoSection";
import MainNavigation from "../components/MainNavigation";
import { useHistory, useParams } from "react-router";
import { trackOrder } from "../store/actions/restaurantsAction";
import moment from "moment";
import OrderCompleted from "../components/OrderCompleted";
import { useRef } from "react";

const Tracking = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { darkMode, color } = useSelector((state) => state.global);
    const { orderTracker } = useSelector((state) => state.restaurants);
    console.log(orderTracker,"OrderTracker>>>>>>>");

    const { id } = useParams();

    useEffect(() => {
        dispatch(trackOrder(id));
        window.scrollTo(0, 0);
    }, []);

    const seconds = orderTracker?.createdAt?.seconds;
    const nano = orderTracker?.createdAt?.nanoseconds;

    const unixTimestamp = seconds * 1000 + nano / 1000000;
    const date = moment.unix(unixTimestamp / 1000);
    const formattedDateTime = date.format("DD MMM YYYY, HH:mm A");
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const steps = [
        {
            rightContent: "Order placed",
            desc: `On ${formattedDateTime}`,
            isComplete:
                orderTracker.status == "pending" ||
                orderTracker.status == "processing" ||
                orderTracker.status == "ready" ||
                orderTracker.status == "refunded" ||
                orderTracker.status == "completed"
                    ? true
                    : false,
        },
        {
            rightContent: "In progress",
            desc: "Your order is under process",
            isComplete:
                orderTracker.status == "ready" ||
                orderTracker.status == "processing" ||
                orderTracker.status == "refunded" ||
                orderTracker.status == "completed"
                    ? true
                    : false,
        },
        {
            rightContent: "Order is Ready",
            desc: "In Tracking",
            isComplete:
                orderTracker.status == "ready" ||
                orderTracker.status == "refunded" ||
                orderTracker.status == "completed"
                    ? true
                    : false,
        },
        {
            rightContent: "Completed",
            desc: "Expect your order in 20 minutes",
            isComplete:
                orderTracker.status == "refunded" ||
                orderTracker.status == "completed"
                    ? true
                    : false,
        },
        {
            rightContent: "Refunded",
            desc: "Refund your  order",
            isComplete: orderTracker.status == "refunded" ? true : false,
        },
    ];
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    // useEffect(() => {
    //     if (orderTracker.status !== "completed") {
    //         setStartTime(new Date());
    //         const intervalId = setInterval(() => {
    //             setTime(new Date());
    //         }, 1000);

    //         return () => clearInterval(intervalId);
    //     } else {
    //         setEndTime(new Date());
    //     }
    // }, [orderTracker]);

    const calculateDuration = () => {
        if (startTime && endTime) {
            const durationMs = endTime.getTime() - startTime.getTime();
            const duration = new Date(durationMs);
            const hours = duration.getUTCHours();
            const minutes = duration.getUTCMinutes();
            const seconds = duration.getUTCSeconds();
            return `${hours}hr ${minutes}mn ${seconds}sec`;
        } else {
            return "";
        }
    };

    return (
        <div className="cart">
            <Container>
                <MainNavigation title={"Tracking"} />
                {/* <div id="google_translate_element"></div> */}
                <Row className="d-flex my-3 flex-column align-items-center justify-content-center">
                    <Col className="d-flex justify-content-center">
                        <h1
                            className={
                                darkMode
                                    ? "fs--32 fw--600 normal-heading-dark"
                                    : "fs--32 fw--600 normal-heading"
                            }
                        >
                            {/* {orderTracker.status !== "completed"
                                ? time.toLocaleTimeString()
                                : calculateDuration()} */}
                        </h1>
                    </Col>
                </Row>
                <Row className="d-flex my-3 flex-column align-items-center justify-content-center">
                    <Col className="d-flex justify-content-start align-items-center pl-5">
                        <Stepper
                            steps={steps}
                            completeColor={`${color}`}
                            darkMode={darkMode}
                            tracker={orderTracker}
                        />
                    </Col>
                </Row>
                <Row className="d-flex my-3 flex-column align-items-center justify-content-center">
                    <Col className="d-flex justify-content-between align-items-center px-3">
                        <h5
                            className={
                                darkMode
                                    ? "fs--22 fw--600 normal-heading-dark"
                                    : "fs--22 fw--600 "
                            }
                        >
                            Preparing
                        </h5>
                        {/* {orderTracker.status == "completed" ? (
                            <>
                                <Button
                                    className="cart__contact_btn text-capitalize"
                                    style={{ background: `${color}` }}
                                    onClick={() => {
                                        toggle();
                                    }}
                                >
                                    FeedBack
                                </Button>
                            </>
                        ) : ( */}
                           {orderTracker?.tableNumber && (
                                <>
                                    <Button
                                        className="cart__contact_btn text-capitalize"
                                        style={{ background: `${color}` }}
                                        onClick={() =>
                                            history.push({
                                                pathname: `/table/${orderTracker?.id}/${orderTracker?.tableNumber}`,
                                                state: orderTracker,
                                            })
                                        }
                                    >
                                        Contact support
                                    </Button>
                                </>
                            )}
                        {/* )} */}
                    </Col>
                </Row>
                <GeneralInfoSection tracker={orderTracker} />
                {/* <Row className="d-flex my-4 flex-column align-items-center justify-content-center">
                    <Col className="d-flex justify-content-start align-items-center px-3">
                        <h5
                            className={
                                darkMode
                                    ? " fs--22 fw--600 mb-0 normal-heading-dark "
                                    : " fs--22 fw--600 mb-0 "
                            }
                        >
                            General Info
                        </h5>
                    </Col>
                    <Col className="d-flex flex-column py-3 px-3">
                        <div className="d-flex justify-content-between px-3 py-2 cart__shipping">
                            <p
                                className={
                                    darkMode
                                        ? "fs--18 fw--400 normal-heading-dark"
                                        : "fs--18 fw--400 "
                                }
                            >
                                Order ID
                            </p>
                            <p
                                className={
                                    darkMode
                                        ? "fs--18 fw--600 normal-heading-dark"
                                        : "fs--18 fw--600 "
                                }
                            >
                                247-96024
                            </p>
                        </div>
                        <div className="d-flex justify-content-between mt-2 px-3">
                            <p
                                className={
                                    darkMode
                                        ? "fs--18 fw--400 normal-heading-dark"
                                        : "fs--18 fw--400 "
                                }
                            >
                                Order Date
                            </p>
                            <p
                                className={
                                    darkMode
                                        ? "fs--18 fw--600 normal-heading-dark"
                                        : "fs--18 fw--600 "
                                }
                            >
                                01/03/2023, 4:20
                            </p>
                        </div>
                    </Col>
                </Row> */}

                <OrderedItems
                    data={orderTracker?.order}
                    title={"Product Info"}
                />
                <OrderedItems
                    data={orderTracker?.paymentMethod}
                    title={"Payment Info"}
                />

                <PricingSection
                    data={{
                        price: orderTracker?.totalPriceItem,
                        total: orderTracker?.totalAmount,
                        tip: orderTracker?.tip,
                        currency: orderTracker?.currency,
                    }}
                />
            </Container>
            <OrderCompleted toggle={toggle} modal={modal} />
        </div>
    );
};

export default Tracking;
