import firebase from "../../config/firebase";

export const darkModeToggle = (payload) => async (dispatch) => {
    dispatch({
        type: "DARKMODE",
        payload: payload,
    });
};
export const colorToggle = (payload) => async (dispatch) => {
    dispatch({
        type: "COLOR",
        payload: payload,
    });
};

export const checkLiked = (menuID, customerID) => async (dispatch) => {
    console.log(menuID,customerID,"menuID and CustomerID>>>>>>>");
    firebase
        .firestore()
        .collection("likedItems")
        .where("menuID", "==", menuID)
        .where("customerID", "==", customerID)
        .onSnapshot(async (query) => {
            if (query.docs.length > 0) {
                dispatch({
                    type: "FAVORITE",
                    payload: true,
                });
            } else {
                dispatch({
                    type: "FAVORITE",
                    payload: false,
                });
            }
        });
};
export const getProduct =
    (id, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            const doc = await firebase
                .firestore()
                .collection("menus")
                .doc(id)
                .get();
            let recommended = doc.data().recommendedProducts;
            let updatedLabels = [];
            let restaurantID = doc.data().restaurantID;

            const newRecommended = [];

            await Promise.all(
                recommended.map(async (item) => {
                    if (item?.selectedOptions) {
                        for (const option of item.selectedOptions) {
                            const query = await firebase
                                .firestore()
                                .collection("menus")
                                .doc(option.id)
                                .get();
                            let data = query.data();
                            newRecommended.push({ id: query.id, data: data });
                        }
                    }
                })
            );

            const data = await firebase
                .firestore()
                .collection("users")
                .doc(restaurantID)
                .get();
            let currencyData = data.data();

            for (const label of doc.data().label) {
                const query = await firebase
                    .firestore()
                    .collection("labels")
                    .doc(label?.id)
                    .get();
                updatedLabels.push({
                    labelName: query.data()?.labelName,
                    labelURL: query.data()?.labelURL,
                });
            }
            dispatch({
                type: "PRODUCT_DETAILS",
                payload: {
                    id: doc.id,
                    ...doc.data(),
                    label: updatedLabels,
                    currency: currencyData.currency,
                    recommendedProducts: newRecommended,
                },
            });
            onSuccess();
        } catch (error) {
            onSuccess();
            console.error("Error fetching data:", error);
        }
    };

// export const getProduct = (id) => async (dispatch) => {
//     await firebase
//         .firestore()
//         .collection("menus")
//         .doc(id)
//         .get()
//         .then((doc) => {
//             let recommended = doc.data().recommendedProducts;
//             recommended.forEach((item) => {
//                 const newRecommended = [];
//                 item?.selectedOptions?.forEach((option) => {
//                     firebase
//                         .firestore()
//                         .collection("menus")
//                         .doc(option.id)
//                         .get()
//                         .then((query) => {
//                             let data = query.data();
//                             console.log(data, "query");
//                             newRecommended.push({ data });
//                         });
//                 });
//                 console.log(newRecommended, "new");
//             });

//     let labels = doc.data().label;
//     let restaurantID = doc.data().restaurantID;
//     let updatedLabels = [];
//     firebase
//         .firestore()
//         .collection("users")
//         .doc(restaurantID)
//         .get()
//         .then((data) => {
//             let currencyData = data.data();
//             for (let label of labels) {
//                 firebase
//                     .firestore()
//                     .collection("labels")
//                     .doc(label?.id)
//                     .onSnapshot((query) => {
//                         updatedLabels.push({
//                             labelName: query.data()?.labelName,
//                             labelURL: query.data()?.labelURL,
//                         });
//                     });
//             }

//             dispatch({
//                 type: "PRODUCT_DETAILS",
//                 payload: {
//                     id: doc.id,
//                     ...doc.data(),
//                     label: updatedLabels,
//                     currency: currencyData.currency,
//                 },
//             });
//         });
//     });

// };

// await firebase
// .firestore()
// .collection("menus")
// .doc(id)
// .get()
// .then((doc) => {
//     let recommended = doc.data().recommendedProducts;
//     const promises = [];

//     recommended.forEach((item) => {
//         const itemPromises = item?.selectedOptions?.map((option) => {
//             return firebase
//                 .firestore()
//                 .collection("menus")
//                 .doc(option.id)
//                 .get()
//                 .then((query) => {
//                     let data = query.data();
//                     console.log(data, "query");
//                     return data;
//                 });
//         });

//         promises.push(...itemPromises);
//     });

//     Promise.all(promises).then((newRecommended) => {
//         console.log(newRecommended, "new");
//         // Here, you have access to the newRecommended array with all the query.data() items
//     });
// });
