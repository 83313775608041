import {
    GET_RESTAURANT,
    GET_CATALOGS,
    GET_CATEGORIES,
    GET_MENUS,
} from "../types";
import {toast} from 'react-toastify';
import firebase from "../../config/firebase";

export const getAllRestaurantList = () => async (dispatch) => {
    try {
        dispatch(requestLoader(true));
        await firebase
            .firestore()
            .collection("users")
            .where("type", "==", "restaurant")
            .where("subScriptionStatus", "==", "subscribe")
            .onSnapshot((query) => {
                let allRestaurants = [];
                for (let doc of query.docs) {
                    if (doc.exists) {
                        allRestaurants.push({ id: doc.id, ...doc.data() });
                    }
                }
                dispatch({
                    type: "ALL_RESTAURANTS",
                    payload: allRestaurants,
                });
            });
        dispatch(requestLoader(false));
    } catch (error) {
        console.log(error);
        dispatch(requestLoader(false));
    }
};

export const getRestaurantsAction = (restaurantID) => async (dispatch) => {
    dispatch(requestLoader(true));
    try {
        firebase
            .firestore()
            .collection("users")
            .where("type", "==", "restaurant")
            .where("restaurantID", "==", restaurantID)
            .onSnapshot(async (query) => {
                let temp = [];
                for (let doc of query.docs) {
                    temp.push({ id: doc.id, ...doc.data() });
                }
                dispatch({ type: GET_RESTAURANT, payload: temp[0] });
                dispatch(requestLoader(false));
            });
    } catch (err) {
        dispatch(requestLoader(false));
        toast.error(err.message);
    }
};
export const getCatalogsAction = (id) => async (dispatch) => {
    try {
        console.log(id,"id>>>>>>>>>");
        firebase
            .firestore()
            .collection("catalogs")
            .where("restaurantID", "==", id)
            .onSnapshot(async (query) => {
                let temp = [];
                for (let doc of query.docs) {
                    temp.push({ id: doc.id, ...doc.data() });
                }
                console.log(temp,"temp>>>>>>>>>>>>");
                dispatch({ type: GET_CATALOGS, payload: temp });
            });
    } catch (err) {
        toast.error(err.message);
    }
};
export const getCategoriesAction = (id) => async (dispatch) => {
    try {
        firebase
            .firestore()
            .collection("categories")
            .where("restaurantID", "==", id)
            .onSnapshot(async (query) => {
                let temp = [];
                for (let doc of query.docs) {
                    temp.push({ id: doc.id, ...doc.data() });
                }
                dispatch({ type: GET_CATEGORIES, payload: temp });
            });
    } catch (err) {
        toast.error(err.message);
    }
};
export const trackOrder = (id) => async (dispatch) => {
    try {
        firebase
            .firestore()
            .collection("orders")
            .doc(id)
            .onSnapshot(async (obj) => {
                const orderData = obj.data()?.order;
                const menuIds = orderData.map((item) => item.menuItemID);
                const menuItemsRefs = menuIds?.map((menuId) =>
                    firebase.firestore().collection("menus").doc(menuId)
                );
                const menuItemsData = await Promise.all(
                    menuItemsRefs.map(async (menuItemsRef) => {
                        const doc = await menuItemsRef.get();
                        return { ...doc.data()?.images[0] };
                    })
                );
                let updatedOrder;
                if (menuItemsData) {
                    updatedOrder = orderData?.map((item, index) => {
                        return {
                            ...item,
                            images: menuItemsData[index],
                        };
                    });
                } else {
                    updatedOrder = orderData?.map((item, index) => {
                        return {
                            ...item,
                            images: "",
                        };
                    });
                }

                let order = obj.data()?.order;
                let newOrderData = {
                    ...obj.data(),
                    order: updatedOrder,
                };
                dispatch({
                    type: "TRACK",
                    payload: { id: obj.id, ...newOrderData },
                });
            });
    } catch (e) {
        toast.error(e.message);
    }
};

export const addRestaurantFeedBack =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        console.log(payload,"restaurantfeedback");
        dispatch(requestLoader(true));
        dispatch({
            type: "ADD_FEED_LOADER",
            payload: true,
        });
        try {
            firebase
                .firestore()
                .collection("restaurantFeedback")
                .add({
                    ...payload,
                    createdAt: firebase.firestore.Timestamp.now(),
                })
                .then(() => {
                    onSuccess();
                    dispatch(requestLoader(false));
                    toast.success("Feedback add successfully");
                    dispatch({
                        type: "ADD_FEED_LOADER",
                        payload: false,
                    });
                })
                .catch((e) => {
                    toast.error(e.message);
                    dispatch(requestLoader(false));
                    dispatch({
                        type: "ADD_FEED_LOADER",
                        payload: false,
                    });
                });
        } catch (e) {
        toast.error(e.message);
            dispatch({
                type: "ADD_FEED_LOADER",
                payload: false,
            });
        }
    };
export const addPlateformFeedBack = (payload) => async (dispatch) => {
    console.log(payload,"Payload");
    try {
        dispatch(requestLoader(true));
        firebase
            .firestore()
            .collection("platformFeedback")
            .add({
                ...payload,
                createdAt: firebase.firestore.Timestamp.now(),
            })
            .then(
                dispatch(requestLoader(false)),
                toast.success("Feedback add successfully")
            )
            .catch((e) => {
                toast.error(e.message);
                dispatch(requestLoader(false));
            });
    } catch (e) {
        toast.error(e.message);
        dispatch(requestLoader(false));
    }
};
export const addRequest =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        const { id, tableNumber, comments, restaurantID, customerID } = payload;
        // console.log({ payload });
        dispatch(requestLoader(true));
        let customerName = "";
        firebase
            .firestore()
            .collection("users")
            .doc(customerID)
            .get()
            .then(async (doc) => {
                let customerName = doc?.data()?.name;
                // console.log(doc.data());
                await firebase
                    .firestore()
                    .collection("requests")
                    .add({
                        orderID: id,
                        tableNumber,
                        comments,
                        restaurantID,
                        customerID,
                        customerName,
                        status: "processing",
                        createdAt: firebase.firestore.Timestamp.now(),
                    })
                    .then(
                        onSuccess(),
                        dispatch(requestLoader(false)),
                        toast.success("Request recorded")
                    )
                    .catch((e) => {
                        toast.error(e.message);
                        dispatch(requestLoader(false));
                    });
            });
    };

export const getMenusAction = (id, category) => async (dispatch) => {
    try {
        firebase
            .firestore()
            .collection("menus")
            .where("restaurantID", "==", id)
            .where("category", "==", category)
            .onSnapshot(async (query) => {
                let temp = [];
                if (!query.empty) {
                    for (let doc of query.docs) {
                        temp.push({ id: doc.id, ...doc.data() });
                    }
                }

                dispatch({ type: GET_MENUS, payload: temp });
            });
    } catch (err) {
        toast.error(err.message);
    }
};

export const addLikedItem =
    (menuID, customerID, onSuccess = () => {}) =>
    async (dispatch) => {
        firebase
            .firestore()
            .collection("likedItems")
            .where("menuID", "==", menuID)
            .where("customerID", "==", customerID)
            .get()
            .then(async (querySnapshot) => {
                if (querySnapshot.docs.length > 0) {
                    return firebase
                        .firestore()
                        .collection("likedItems")
                        .doc(querySnapshot.docs[0].id)
                        .delete()
                        .then(() => {
                            onSuccess();
                        });
                } else {
                    return firebase
                        .firestore()
                        .collection("likedItems")
                        .add({ menuID, customerID })
                        .then(() => {
                            onSuccess();
                        });
                }
            })
            .catch((error) => {
                console.error("Error adding or updating document: ", error);
            });
    };

export const getLikedItems = (uid) => async (dispatch) => {
    firebase
        .firestore()
        .collection("likedItems")
        .where("customerID", "==", uid)
        .onSnapshot(async (query) => {
            let menuData = [];
            for (let doc of query.docs) {
                let docData = doc.data();
                await firebase
                    .firestore()
                    .collection("menus")
                    .doc(docData.menuID)
                    .get()
                    .then((menu) => {
                        let tempData = menu.data();
                        menuData.push({
                            id: menu.id,
                            ...menu.data(),
                            // src: tempData?.images?.[0],
                            // title: tempData?.name,
                            // desc: tempData?.estimatedTime,
                            // price: tempData?.sizes[0]?.price,
                        });
                    });
            }
            dispatch({
                type: "LIKED_ITEMS",
                payload: menuData,
            });
        });
};

export const requestLoader = (val) => async (dispatch) => {
    dispatch({
        type: "REQUESTLOADING",
        payload: val,
    });
};
