import { LOGIN, LOGOUT, AUTH_SET_LOADING, SINGLE_USER,LOGINAGAIN } from "../types";
import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import {toast} from 'react-toastify';
const provider = new firebase.auth.GoogleAuthProvider();
let cartPath = localStorage.getItem("cartPath");

export const signupLoader = (val) => async (dispatch) => {
    dispatch({
        type: AUTH_SET_LOADING,
        payload: val,
    });
};
export const signUpAction = (payload, onSuccess) => async (dispatch) => {
    dispatch(signupLoader(true));
    firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then((data) => {
            firebase
                .firestore()
                .collection("users")
                .doc(data.user.uid)
                .set({
                    name: payload.name,
                    email: payload.email,
                    onetimelogin:true,
                    contactNumber: payload.contactNumber,
                    type: "customer",
                    gender: payload.gender,
                    age: payload.age,
                    DOB: payload?.DOB,
                    address: "",
                    imageURL: "",
                    createdAt: firebase.firestore.Timestamp.now(),
                })
                .then((res) => {
                    dispatch(signupLoader(false));
                    toast.success("sign up successfully");
                    var user=firebase.auth().currentUser;
                    user.sendEmailVerification().then(()=>{
                      toast.success("Email Verfication has been sent!");
                    }).catch((error)=>{
                      toast.error(error.message);
                    })
                    onSuccess();
                })
                .catch((err) => {
                    dispatch(signupLoader(false));
                    toast.error(err.message);
                });
        })
        .catch((err) => {
            dispatch(signupLoader(false));
            toast.error(err.message);
        });
};
export const signInAction = (payload, onSuccess) => async (dispatch) => {
    dispatch(signupLoader(true));
    try {
        dispatch(signupLoader(true));
        await firebase
            .auth()
            .signInWithEmailAndPassword(payload.email, payload.password)
            .then((userCredential) => {
                var uid = userCredential.user.uid;
                var user=userCredential.user;
                if(user.emailVerified){
                firebase
                    .firestore()
                    .collection("users")
                    .doc(uid)
                    .onSnapshot(async (query) => {
                        if (query.data()?.type == "customer") {
                            if (query.data().isDeleted) {
                                toast.warning(
                                    "Your account data have been deleted from database. But the good news is, your account is still alive. Please contact your admin to create a new slot for you to store data then you will be able to login."
                                );
                            } else {
                                dispatch({
                                    type: LOGIN,
                                    payload: {
                                        uid: uid,
                                        user: { id: uid, ...query.data() },
                                    },
                                });
                                onSuccess();
                                dispatch(signupLoader(false));
                            }
                        } else {
                            toast.error("This User does not Exist");
                            dispatch(signupLoader(false));
                        }
                    });
                }else{
                    throw new Error("Email Verification is not verified!");
                }
            })
            .catch((err) => {
                dispatch(signupLoader(false));
        toast.error(err.message);
            });
    } catch (error) {
        dispatch(signupLoader(false));
        toast(error.message);
    }
};
export const signInWithGoogle = (history) => async (dispatch) => {
    firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
            const uid = result?.user?.uid;
            firebase
                .firestore()
                .collection("users")
                .doc(uid)
                .onSnapshot(async (query) => {
                    if (query.data()?.type == "customer") {
                        let data = query.data();
                        if (query.data().isDeleted) {
                            toast.warning(
                                "Your account data have been deleted from database. But the good news is, your account is still alive. Please contact your admin to create a new slot for you to store data then you will be able to login."
                            );
                        } else {
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    uid: uid,
                                    user: { id: uid, ...query.data() },
                                },
                            });

                            cartPath
                                ? history.push("/cart")
                                : history.push(`/restaurants`);
                        }
                    } else {
                        firebase
                            .firestore()
                            .collection("users")
                            .doc(uid)
                            .set({
                                imageURL: "",
                                name: result?.user?.displayName,
                                email: result?.user?.email,
                                type: "customer",
                                createdAt: firebase.firestore.Timestamp.now(),
                            })
                            .then((res) => {
                                toast.success("sign up successfully");
                                history.push("/restauarants");
                            });
                    }
                });
        })
        .catch((error) => {
            // handle authentication error
        });
};
export const logoutAction = () => async (dispatch) => {
    firebase
        .auth()
        .signOut()
        .then((data) => {
            localStorage.clear();
            window.location.href = "/signin";
        })
        .catch((error) => {});
};

export const getSingleUser = (id) => async (dispatch) => {
    await firebase
        .firestore()
        .collection("users")
        .doc(id)
        .get()
        .then((res) => {
            dispatch({
                type: SINGLE_USER,
                payload: {
                    id: id,
                    ...res.data(),
                },
            });
        });
};
export const updateProfileImageAction = (payload) => async (dispatch) => {
    try {
        if (payload?.image?.name) {
            let docData = await firebase
                .firestore()
                .collection("users")
                .doc(payload?.id)
                .get();
            if (docData.data()?.imageURL !== "") {
                await firebase
                    .storage()
                    .refFromURL(docData.data().imageURL)
                    .delete();
            }
            let fileName = payload?.image?.name;
            // console.log("fileName", fileName);
            let fileName1 = fileName?.slice(fileName.lastIndexOf("."));
            let fileName2 = uuidv4() + fileName1?.toLowerCase();
            let storageRef = await firebase
                .storage()
                .ref(fileName2)
                .put(payload?.image);
            let urlFeatured = await storageRef.ref.getDownloadURL();

            await firebase
                .firestore()
                .collection("users")
                .doc(payload.id)
                .update({
                    imageURL: urlFeatured,
                })
                .then(() => {
                toast.success("Profile Images Updated Successfuly");
                })
                .catch((e) => {
                    toast.error(e.message);
                });
        }
    } catch (err) {
        toast.error(err.message);
    }
};
export const updateProfileAction =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        // console.log({ payload });
        dispatch(signupLoader(true));
        try {
            await firebase
                .firestore()
                .collection("users")
                .doc(payload.id)
                .update({
                    ...payload,
                    onetimelogin:false,
                })
                .then(() => {
                    toast.success("Profile updated successfully!");
                    dispatch({
                        type: LOGINAGAIN,
                    });
                    dispatch(getSingleUser(payload.id));
                    dispatch(signupLoader(false));
                    onSuccess();
                })
                .catch((e) => {
                    toast.error(e.message);
                    dispatch(signupLoader(false));
                });
        } catch (err) {
            toast.error(err.message);
        }
    };
export const updateUserAction = (payload, onSuccess) => async (dispatch) => {
    try {
        await firebase
            .firestore()
            .collection("users")
            .doc(payload.id)
            .update({
                ...payload,
            })
            .then(() => {
                onSuccess();
            })
            .catch((e) => {
                toast.error(e.message);
            });
    } catch (err) {
        toast.error(err.message);
    }
};
export const updateThemeAction = (payload) => async (dispatch) => {
    try {
        firebase
            .firestore()
            .collection("users")
            .doc(payload.id)
            .update({
                ...payload,
            })
            .then(() => {
                dispatch(getSingleUser(payload.id));
                dispatch({
                    type: "DARKMODE",
                    payload: payload.darkMode,
                });
            })
            .catch((e) => {
                toast.error(e.message);
            });
    } catch (err) {
        toast.error(err.message);
    }
};
// export const updateColorAction = (payload) => async (dispatch) => {
//     try {
//         firebase
//             .firestore()
//             .collection("users")
//             .doc(payload.id)
//             .update({
//                 ...payload,
//             })
//             .then(() => {
//                 dispatch(getSingleUser(payload.id));
//                 dispatch({
//                     type: "COLOR",
//                     payload: payload.mainColor,
//                 });
//             })
//             .catch((e) => {
//                 alert(e.message);
//             });
//     } catch (err) {
//         alert(err.message);
//     }
// };
