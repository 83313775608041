/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import arrow from "../assets/img/arrow.svg";
import arrowWhite from "../assets/img/arrowWhite.svg";
import info from "../assets/img/info.svg";
import infoWhite from "../assets/img/infoWhite.svg";
import msg from "../assets/img/msg.svg";
import msgWhite from "../assets/img/msgWhite.svg";
import request from "../assets/img/request.svg";
import requestWhite from "../assets/img/requestWhite.svg";
import profile from "../assets/img/restaurantProfile.png";
import GoogleTranslate from "../components/GoogleTranslate";
import firebase from "../config/firebase";
import { colorToggle } from "../store/actions/GlobalAction";
import {
    getParentMenus,
} from "../store/actions/MenuActions";
import { restaurantVisitorAdd } from "../store/actions/orderAction";
import { getRestaurantsAction } from "../store/actions/restaurantsAction";
import { clearCartAction } from "../store/actions/cartAction";

const RestaurantHome = () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { darkMode, color } = useSelector((state) => state.global);
    const { uid } = useSelector((state) => state.authUser);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);
    const { menuLoading, parentMenuData } = useSelector(
        (state) => state.parentMenu
    );
    const pageRoutes = [
        {
            dark: request,
            light: requestWhite,
            title: "Request service",
            value: "table/request/tableNo",
        },
        {
            dark: msg,
            light: msgWhite,
            title: "Give Feedback",
            value: "feedback",
        },
        {
            dark: info,
            light: infoWhite,
            title: "Info",
            value: "info",
        },
    ];

    useEffect(() => {
        const restaurantID = localStorage.getItem("restaurantID");
        if (restaurantID !== id) {
            localStorage.removeItem("cart");
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            const prevRestID = localStorage.getItem("restaurantID");
            if (prevRestID !== id) {
                dispatch(clearCartAction());
            }
            dispatch(getRestaurantsAction(id));
            localStorage.setItem("restaurantID", id);
        } else {
            toast.warning("Link broken");
            history.push("/signin");
        }
    }, [id]);

    useEffect(() => {
        if (restaurantsDetail && restaurantsDetail?.customerColor) {
            localStorage.setItem("mainColor", restaurantsDetail?.customerColor);
            dispatch(colorToggle(restaurantsDetail?.customerColor));
        }
    }, [dispatch, restaurantsDetail]);

    useEffect(() => {
        dispatch(getParentMenus(id));
    }, [id]);

    useEffect(() => {
        if (id) {
            let obj = {
                createdAt: firebase.firestore.Timestamp.now(),
                restaurantID: id,
            };
            dispatch(restaurantVisitorAdd(obj));
        }
    }, []);
    return (
        <div
            className={
                darkMode
                    ? "restaurant__home__dark py-5"
                    : "restaurant__home py-5"
            }
        >
            <Container
                className={`restaurant__profile ${
                    darkMode ? "screen_border" : "screen_border_dark"
                }`}
            >
                <Row className="d-flex flex-column justify-content-center align-items-center">
                    <Col className="d-flex justify-content-center align-items-center mb-3">
                        <img
                            className="restaurant__profile__img"
                            src={restaurantsDetail?.restaurantLogo || profile}
                            alt="restaurnt profile"
                        />
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center mb-3">
                        <h6 className="text-capitalize restaurant__profile__name">
                            {restaurantsDetail?.name}
                        </h6>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center mb-3">
                        <GoogleTranslate />
                    </Col>
                </Row>
                <Row className="d-flex flex-column justify-content-center align-items-center pb-4">
                    {menuLoading ? (
                        <Spinner size="sm" color="primary" />
                    ) : (
                        <>
                            {parentMenuData?.length > 0 ? (
                                parentMenuData?.map((category, index) => {
                                    return (
                                        <Button
                                            onClick={() => {
                                                const now = new Date();
                                                const currentDay =
                                                    now.toLocaleString(
                                                        "en-US",
                                                        { weekday: "long" }
                                                    );
                                                const currentTime =
                                                    now.getHours() * 100 +
                                                    now.getMinutes();
                                                if (
                                                    restaurantsDetail &&
                                                    restaurantsDetail.storeTiming
                                                ) {
                                                    const todayTimings =
                                                        restaurantsDetail?.storeTiming.find(
                                                            (timing) => {
                                                                if (
                                                                    timing.day ===
                                                                    currentDay
                                                                ) {
                                                                    const startTime =
                                                                        timing.startTime
                                                                            .split(
                                                                                ":"
                                                                            )
                                                                            .map(
                                                                                Number
                                                                            );
                                                                    const endTime =
                                                                        timing.endTime
                                                                            .split(
                                                                                ":"
                                                                            )
                                                                            .map(
                                                                                Number
                                                                            );
                                                                    const storeStartTime =
                                                                        startTime[0] *
                                                                            100 +
                                                                        startTime[1];
                                                                    const storeEndTime =
                                                                        endTime[0] *
                                                                            100 +
                                                                        endTime[1];

                                                                    return (
                                                                        currentTime >=
                                                                            storeStartTime &&
                                                                        currentTime <=
                                                                            storeEndTime
                                                                    );
                                                                }
                                                                return false;
                                                            }
                                                        );

                                                    if (
                                                        !todayTimings ||
                                                        !todayTimings.startTime ||
                                                        !todayTimings.endTime
                                                    ) {
                                                        toast.warning(
                                                            "Sorry! the store is closed"
                                                        );
                                                        return false;
                                                    } else {
                                                        history.push(
                                                            `/restaurant-details/${category?.id}`
                                                        );
                                                    }
                                                } else {
                                                    toast.error(
                                                        "Unable to retrieve store timings"
                                                    );
                                                    return false;
                                                }
                                            }}
                                            key={index}
                                            className="mb-3 restaurant__profile__button"
                                            style={{ background: `${color}` }}
                                        >
                                            Go to {category?.name}
                                        </Button>
                                    );
                                })
                            ) : (
                                <h6 className="text-capitalize restaurant__profile__name">
                                    No Menu Exists in this Restaurant
                                </h6>
                            )}
                        </>
                    )}
                </Row>
                <hr className="restaurant__profile__divider" />
                {pageRoutes?.map((page, idx) => {
                    return (
                        <div
                            key={idx}
                            onClick={() => {
                                if (page.title !== "Request service") {
                                    history.push(`/${page.value}`);
                                } else {
                                    history.push({
                                        pathname: `/${page.value}`,
                                        state: {
                                            restaurantID: id,
                                            customerID: uid,
                                        },
                                    });
                                }
                            }}
                        >
                            <Row className="restaurant_profile__page_route cursor-pointer">
                                <Col className="col-10 d-flex justify-content-start align-items-center">
                                    <img
                                        src={
                                            darkMode ? page?.light : page?.dark
                                        }
                                        className="icon mx-2"
                                        alt="icon"
                                    />
                                    <p className="mb-0 restaurant__profile__name ">
                                        {page?.title}
                                    </p>
                                </Col>
                                <Col className="col-2 d-flex justify-content-end align-items-center">
                                    <img
                                        src={darkMode ? arrowWhite : arrow}
                                        className="arrow_icon"
                                        alt="arrow_icon"
                                    />
                                </Col>
                            </Row>
                            <hr className="restaurant__profile__divider" />
                        </div>
                    );
                })}
            </Container>
        </div>
    );
};

export default RestaurantHome;
