/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Main = (props) => {
    const { darkMode } = useSelector((state) => state.global);
    const { singleUser } = useSelector((state) => state.user);
    const { uid } = useSelector((state) => state.authUser);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);

    const location = useLocation();
    const showBackground =
        location.pathname === `/restaurants` ||
        location.pathname ===
            `/restaurant/${restaurantsDetail?.restaurantID}` ||
        location.pathname === "/info" ||
        location.pathname === "/feedback" ||
        location.pathname === "/table/request/tableNo";

    const showNavbar =
        location.pathname === "/profile" ||
        location.pathname === "/info" ||
        location.pathname === "/feedback" ||
        location.pathname === "/table/request/tableNo" ||
        location.pathname === `/restaurant/${restaurantsDetail?.restaurantID}`;

    const languagesArray = restaurantsDetail?.languages
        ? restaurantsDetail?.languages
        : ["en"];
    const languagesString = languagesArray.join(",");
    let includedLanguages = languagesString;
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                includedLanguages: includedLanguages,
                autoDisplay: true,
            },
            "google_translate_element"
        );
    };

    useEffect(() => {
        if (restaurantsDetail?.languages) {
            var googleTranslator = document.getElementById(
                "google-translator-script"
            );
            if (!googleTranslator) {
                var addScript = document.createElement("script");

                addScript.setAttribute(
                    "src",
                    "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                );

                addScript.setAttribute("id", "google-translator-script");
                document.body.appendChild(addScript);
                window.googleTranslateElementInit = googleTranslateElementInit;
            }
        }
    }, [restaurantsDetail?.languages]);

    const className = darkMode ? "main-bg-dark" : "main-bg";

    return (
        <>
            <div className={className}>
                {!showBackground &&
                location.pathname !== "/profile" &&
                location.pathname !== "/restaurants" ? (
                    uid ? (
                        <>
                            {" "}
                            {!singleUser?.age && (
                                <div className="marquee-container py-2">
                                    <div className="marquee-content text-danger">
                                        Attension! Please add your DOB to place
                                        an order{" "}
                                        <Link to="/profile">
                                            Profile Details
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="marquee-container py-2">
                                <div className="marquee-content text-danger">
                                    Attension! Please Login or Register to place
                                    your order <Link to="/signin">Login</Link>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    ""
                )}

                {!showNavbar ? <Header /> : <div></div>}
                {props.children}
                <Footer />
            </div>{" "}
        </>
    );
};

export default Main;
