import React from "react";
import { Col, Container, Row } from "reactstrap";
import MainNavigation from "../components/MainNavigation";
import { useSelector,useDispatch } from "react-redux";
import { getRestaurantsAction } from "../store/actions/restaurantsAction";
import { useEffect } from "react";
const Info = () => {
    const dispatch=useDispatch();
    const { darkMode, color } = useSelector((state) => state.global);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);
    // console.log(restaurantsDetail.storeTiming, "store");
    useEffect(() => {
        const ID=localStorage.getItem('restaurantID');
        if (ID) {
            dispatch(getRestaurantsAction(ID));
        }
    }, []);
    const storeDays = [
        {
            day: "monday",
            time: "10:30 - 4:20",
        },
        {
            day: "monday",
            time: "10:30 - 4:20",
        },
        {
            day: "monday",
            time: "10:30 - 4:20",
        },
        {
            day: "monday",
            time: "10:30 - 4:20",
        },
        {
            day: "monday",
            time: "10:30 - 4:20",
        },
        {
            day: "monday",
            time: "10:30 - 4:20",
        },
    ];

    return (
        <div className={darkMode ? "info__main__dark" : "info__main"}>
            <Container className="info">
                <MainNavigation title={"Info"} />
                <Row className="d-flex flex-column justify-content-center align-items-center">
                    <Col>
                        <h4
                            style={{ color: `${color}` }}
                            className="info__restaurant_name"
                        >
                            {restaurantsDetail?.name}
                        </h4>
                    </Col>
                    <Col>
                        <h6 className="info__sub_heading">Stay Safe</h6>
                    </Col>
                </Row>
                <hr className="info__divider" />
                <Row className="d-flex flex-column justify-content-center align-items-center">
                    <Col>
                        <h5 className="info__heading">Address</h5>
                    </Col>
                    <Col>
                        <h6 className="info__sub_heading">
                            {restaurantsDetail?.location}
                        </h6>
                        <h6 className="info__sub_heading">
                            {restaurantsDetail?.location}
                        </h6>
                    </Col>
                </Row>
                {/* <hr className="info__divider" />
                <Row className="d-flex flex-column justify-content-center align-items-center">
                    <Col>
                        <h5 className="info__heading">Need help?</h5>
                    </Col>
                    <Col>
                        <h6 className="info__sub_heading">
                            Call us on <a href="tel:++0909090900"> +0909090900</a>
                        </h6>
                    </Col>
                </Row> */}
                <hr className="info__divider" />
                <h5 className="info__heading">Opening hours for Pickup</h5>
                {restaurantsDetail?.pickupDays?.map((item,index) => {
                    return (
                        <Row key={index} className="d-flex justify-content-between align-items-center mx-2">
                            <h5 className="info__sub_heading">{item?.day}</h5>
                            <h5 className="info__sub_heading">
                                {item?.startTime} - {item?.endTime}
                            </h5>
                        </Row>
                    );
                })}
                <hr className="info__divider" />
                <h5 className="info__heading">Opening hours for Delivery</h5>
                {restaurantsDetail?.deliveryDays?.map((item,ind) => {
                    return (
                        <Row key={ind} className="d-flex justify-content-between align-items-center mx-2">
                            <h5 className="info__sub_heading">{item?.day}</h5>
                            <h5 className="info__sub_heading">
                                {item?.startTime} - {item?.endTime}
                            </h5>
                        </Row>
                    );
                })}
                 <hr className="info__divider" />
                <h5 className="info__heading">Store Timings</h5>
                {restaurantsDetail?.storeTiming?.map((item,id) => {
                    return (
                        <Row key={id} className="d-flex justify-content-between align-items-center mx-2">
                            <h5 className="info__sub_heading">{item?.day}</h5>
                            <h5 className="info__sub_heading">
                                {item?.startTime} - {item?.endTime}
                            </h5>
                        </Row>
                    );
                })}
            </Container>
        </div>
    );
};

export default Info;
