import React from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import MainNavigation from "../components/MainNavigation";
import image from "../assets/img/Image.svg";
import { useDispatch, useSelector } from "react-redux";
import completed from "../assets/img/completed.svg";
import incomplete from "../assets/img/incomplete.svg";
import cancel from "../assets/img/cancel.svg";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { getOrderHistoryAction } from "../store/actions/orderAction";
import { useState } from "react";
import moment from "moment";

const OrderHistory = () => {
    const { darkMode, color } = useSelector((state) => state.global);
    const { uid } = useSelector((state) => state.authUser);
    const history = useHistory();
    const orders = [
        {
            id: 1,
            src: image,
            title: "Peperoni Pizza",
            date: "21 dec 2022",
            items: "5",
            price: "99.00",
            status: "completed",
        },
        {
            id: 2,
            src: image,
            title: "Peperoni Pizza",
            date: "21 dec 2022",
            items: "5",
            price: "19.00",
            status: "incomplete",
        },
        {
            id: 3,
            src: image,
            title: "Peperoni Pizza",
            date: "21 dec 2022",
            items: "5",
            price: "19.00",
            status: "incomplete",
        },
        {
            id: 4,
            src: image,
            title: "Peperoni Pizza",
            date: "21 dec 2022",
            items: "5",
            price: "19.00",
            status: "cancel",
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOrderHistoryAction(uid));
    }, []);

    const { orderHistory } = useSelector((state) => state.orders);

    const totalquantity = (order) => {
        const totalQuantity = order?.reduce((acc, item) => {
            return acc + item?.quantity;
        }, 0);
        return totalQuantity;
    };
    const totalPriceOrder = (order) => {
        const totalPrice = order?.reduce((acc, item) => {
            return acc + item?.price;
        }, 0);
        return totalPrice;
    };

    return (
        <Container className="order_history pb-3">
            <MainNavigation title={"Order History"} />

            {orderHistory?.map((item,index) => {
                return (
                    <Card 
                        className={`p-3 mb-3 cursor-pointer ${
                            darkMode
                                ? "order_history__card-dark"
                                : "order_history__card"
                        }`}
                        key={index}
                    >
                        <Row className="d-flex justify-content-start align-items-center">
                            {/* <Col className="col-3 d-flex justify-content-center align-items-center mx-4">
                                <img src={item.src} />
                            </Col> */}
                            <Col
                                className={`col-7  d-flex justify-content-between ${
                                    darkMode
                                        ? "order_history__content-dark"
                                        : "order_history__content"
                                }`}
                            >
                                <div className="d-flex flex-column justify-content-center align-items-start">
                                    <p className="mb-2 fs--10 typograpghy">
                                        ID : {item?.id}
                                    </p>
                                    <h3 className="mb-3 fs--18 fw--600 title">
                                        {item?.order?.map((obj,id) => (
                                            <React.Fragment key={id}>{obj?.name},</React.Fragment>
                                        ))}
                                    </h3>

                                    <p className="mb-2 fs--12 typograpghy">
                                        <>
                                            {totalquantity(item?.order)} items |{" "}
                                            {moment
                                                .unix(item?.createdAt?.seconds)
                                                .format("DD MMMM YYYY")}{" "}
                                        </>
                                    </p>
                                    <p
                                        className="mb-0 fs--14 price fw--600"
                                        style={{ color: `${color}` }}
                                    >
                                        {/* $ {totalPriceOrder(item?.order)} */}
                                        $ {item?.totalAmount}
                                    </p>
                                </div>
                                <div className="d-flex flex-column justify-content-between align-items-center">
                                    <div
                                        className="d-flex justify-content-center align-items-center cursor-pointer"
                                        onClick={() => {
                                            history.push(
                                                `/tracking/orderID=${item.id}`
                                            );
                                        }}
                                    >
                                        <p className="mb-0 mx-2 fs--16 order_status text-capitalize">
                                            {item.status}
                                        </p>
                                        <img
                                            src={
                                                item.status == "Completed"
                                                    ? completed
                                                    : item.status == "Refunded"
                                                    ? cancel
                                                    : incomplete
                                            }
                                            alt="status"
                                        />
                                    </div>
                                    <div>
                                        {/* <Button
                                            className="order_btn mr-3"
                                            style={{ background: `${color}` }}
                                        >
                                            Repeat Order
                                        </Button> */}
                                        <Button
                                            className="order_btn"
                                            style={{ background: `${color}` }}
                                            onClick={()=>history.push(`/tracking/orderID=${item.id}`)}
                                        >
                                            track Order
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col className="col-3">
                              
                            </Col> */}
                        </Row>
                    </Card>
                );
            })}
        </Container>
    );
};

export default OrderHistory;
