import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import HistoryDark from "../assets/img/HistoryDark.svg";
import HistoryLight from "../assets/img/HistoryLight.svg";
import cart from "../assets/img/cart.png";
import cartDark from "../assets/img/cartDark.png";
import darkModeDark from "../assets/img/darkModeDark.svg";
import darkModeLight from "../assets/img/darkModeLight.svg";
import feedback from "../assets/img/feedback.svg";
import logout from "../assets/img/logout.svg";
import myProfileDark from "../assets/img/myProfileDark.svg";
import myprofile from "../assets/img/myprofile.svg";
import paymentMethodDark from "../assets/img/paymentMethodDark.svg";
import paymentMethodLight from "../assets/img/paymentMethodLight.svg";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { darkModeToggle } from "../store/actions/GlobalAction";
import {
    getSingleUser,
    logoutAction,
    updateThemeAction,
} from "../store/actions/authAction";

const SideBar = ({
    toggleSidebar,
    sidebarRef,
    sidebarOpen,
    setSidebarOpen,
    isActive,
    setIsActive,
}) => {
    const history = useHistory();
    const { darkMode, color } = useSelector((state) => state.global);
    let { uid, user } = useSelector((state) => state.authUser);
    const { singleUser } = useSelector((state) => state.user);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history]);
    const dispatch = useDispatch();
    const [isDarkMode, setIsDarkMode] = useState();
    useEffect(() => {
        setIsDarkMode(singleUser.darkMode);
    }, [singleUser]);
    useEffect(() => {
        if (uid) {
            dispatch(getSingleUser(uid));
        }
    }, [uid]);

    const location = useLocation();
    useEffect(() => {
        if (
            !uid &&
            (location.pathname == "/profile" ||
                location.pathname == "/order-history" ||
                location.pathname == "/payment-method")
        ) {
            history.push("/signin");
        }
        if (!uid && location.pathname == "/payment") {
            localStorage.setItem("cartPath", "/cart");
            toast.warning("First you have to login");
            history.push("/signin");
        }
        window.scrollTo(0, 0);
    }, [uid, location]);

    useEffect(() => {
        if (uid && isDarkMode != undefined) {
            dispatch(
                updateThemeAction({
                    id: uid,
                    darkMode: isDarkMode,
                })
            );
        } else if (!uid && isDarkMode != undefined) {
            dispatch(darkModeToggle(isDarkMode));
        }
    }, [isDarkMode]);

    const toggleNavigation = (value) => {
        if (value == "darkmode") {
            setIsDarkMode(!darkMode);
        } else {
            history.push(`/${value}`);
        }

        setSidebarOpen(false);
    };

    const navigations = [
        {
            id: 1,
            name: "Login",
            src: myprofile,
            src_dark: myProfileDark,
            value: "signin",
        },
        {
            id: 2,
            name: "my profile",
            src: myprofile,
            src_dark: myProfileDark,
            value: "profile",
        },
        {
            id: 3,
            name: "Cart",
            src: cart,
            src_dark: cartDark,
            value: "cart",
        },
        {
            id: 5,
            name: "Dark Mode",
            src: darkModeLight,
            src_dark: darkModeDark,
            value: "darkmode",
        },
        // {
        //     id: 6,
        //     name: "Payment Method",
        //     src: paymentMethodLight,
        //     src_dark: paymentMethodDark,
        //     value: "payment-method",
        // },
        {
            id: 7,
            name: "History",
            src: HistoryLight,
            src_dark: HistoryDark,
            value: "order-history",
        },
    ];
    return (
        <>
            <div
                ref={sidebarRef}
                className={sidebarOpen ? "sidebar open" : "sidebar"}
            >
                <Row
                    className="sidebar__top-row mx-0"
                    style={{ background: `${color}` }}
                >
                    <Col className="sidebar__top-row_profile">
                        {uid && singleUser?.imageURL ? (
                            <div className="text-center">
                                <img
                                    width={70}
                                    height={70}
                                    alt="User"
                                    src={singleUser?.imageURL}
                                    className="mb-2 rounded"
                                />
                            </div>
                        ) : (
                            <FaUser className="fs--60 " />
                        )}

                        <h4 className="fs--18 fw--600 my-2">
                            {uid
                                ? singleUser?.name
                                    ? singleUser?.name
                                    : "N/A"
                                : "N/A"}
                            {}
                        </h4>
                        <p className="fs--14 fw--500 mb-1">
                            {uid
                                ? singleUser?.email
                                    ? singleUser?.email
                                    : "N/A"
                                : "N/A"}
                            {}
                        </p>
                    </Col>
                    <div
                        onClick={toggleSidebar}
                        className="float-right sidebar__cancel"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#ffffff"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#ffffff"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                </Row>
                <Row className="py-4 ml-0 mr-0 sidebar__menu d-flex flex-column justify-content-between">
                    <Col>
                        {navigations?.map((item, index) => {
                            return uid
                                ? item.name != "Login" && (
                                      <React.Fragment key={index}>
                                          <div
                                              className="my-4 sidebar__menu__navigation"
                                              key={item.id}
                                              onClick={() =>
                                                  toggleNavigation(item.value)
                                              }
                                          >
                                              <p className="fs--18 fw--400 d-flex justify-content-start align-items-center text-capitalize">
                                                  <span className="mr-4">
                                                      <img
                                                          alt="item_image"
                                                          width={23}
                                                          src={
                                                              darkMode === true
                                                                  ? item.src_dark
                                                                  : item.src
                                                          }
                                                      />
                                                  </span>
                                                  {darkMode === true
                                                      ? item?.name ==
                                                        "Dark Mode"
                                                          ? "Light Mode"
                                                          : item.name
                                                      : item.name}
                                              </p>
                                          </div>
                                      </React.Fragment>
                                  )
                                : item.name != "my profile" &&
                                      item.name != "Payment Method" &&
                                      item.name != "History" && (
                                          <>
                                              <div
                                                  className="my-4 sidebar__menu__navigation"
                                                  key={item.id}
                                                  onClick={() =>
                                                      toggleNavigation(
                                                          item.value
                                                      )
                                                  }
                                              >
                                                  <p className="fs--18 fw--400 d-flex justify-content-start align-items-center text-capitalize">
                                                      <span className="mr-4">
                                                          <img
                                                              alt="Item_image"
                                                              width={23}
                                                              src={
                                                                  darkMode ===
                                                                  true
                                                                      ? item.src_dark
                                                                      : item.src
                                                              }
                                                          />
                                                      </span>
                                                      {darkMode === true
                                                          ? item?.name ==
                                                            "Dark Mode"
                                                              ? "Light Mode"
                                                              : item.name
                                                          : item.name}
                                                  </p>
                                              </div>
                                          </>
                                      );
                        })}
                    </Col>
                    <Col
                        className={
                            uid
                                ? "d-flex p-0"
                                : "d-flex p-0 justify-content-end"
                        }
                    >
                        {uid ? (
                            <Button
                                className="py-2 my-5 fs--15 fw--600 d-flex justify-content-center align-items-center sidebar__button mx-3"
                                style={{ background: `${color}` }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(logoutAction());
                                }}
                            >
                                <span>
                                    <img
                                        alt="logout"
                                        src={logout}
                                        className="mr-2"
                                    />
                                </span>
                                Logout
                            </Button>
                        ) : null}

                        {}
                        <Button
                            className="py-2 my-5 fs--15 fw--600 d-flex justify-content-center align-items-center sidebar__button_feedback  mr-3 cursor-pointer"
                            onClick={() => history.push("/feedback")}
                        >
                            <span>
                                <img
                                    alt="feedback"
                                    src={feedback}
                                    className="mr-2"
                                />
                            </span>
                            Feedback
                        </Button>
                    </Col>
                </Row>
            </div>
            {}
        </>
    );
};

export default SideBar;
